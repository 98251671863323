@use '../../../styles/colors.scss';

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 32px;
  font-weight: 900;
  color: colors.$primary;
  height: 4em;
  width: 4em;
  border-radius: 50%;
  background: #fff;
}