.center {
  width: 100%;
  max-width: 360px;

  text-align: center;
}

.errBtn {
  width: 130px;
  margin: 0 auto;
}