.root {
  position: sticky;
  top: 20px;
  display: block;
}

.avatar {
  $size: calc(75vh - 330px);
  position: relative;

  border-radius: 50%;
  width: $size;
  height: $size;
  min-width: 150px;
  max-width: 330px;
  min-height: 150px;
  max-height: 330px;
  margin: 0 auto;
  margin-bottom: 20px;
  background: #141414;

  @media (max-width: 1200px) and (min-height: 700px) {
    max-height: 280px;
    max-width: 280px;
  }

  @media (max-width: 750px) {
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.avatarImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  object-position: center;
}

.title {
  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 16px;
    margin: 8px 0;
  }
}

.text {
  color: #aaa;

  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 12px;
    margin: 8px 0;
  }
}