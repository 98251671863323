.root {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-height: 600px) and (min-width: 1500px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.canSelect .item {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.item {
  display: block;
  position: relative;
  padding-top: 100%;
  border-radius: 12px;
  background: #141414;
  overflow: hidden;
  transition: 0.3s;
  -webkit-tap-highlight-color: transparent;
  aspect-ratio: 1 / 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.selected {
  transform: scale(0.95);
}

.checkbox {
  display: none;
}

.checkboxLabel {
  position: absolute;
  top: 10px;
  right: 10px;

  & :global {
    animation: fadeIn 0.3s forwards;
  }
}