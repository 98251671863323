@import './styles/fonts';
@import './styles/animations';

* {
  box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    background-color: #000000;
    color: #ffffff;
    min-height: 100vh;
    font-family: 'SF Pro Display', -apple-system, Inter, Arial, sans-serif;
}