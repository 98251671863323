.wrapper {
  display: inline-block;
}

.radio {
  display: none;
}

.radio:checked ~ .thumb {
  background: #fff;
}

.thumb {
  max-height: 20px;
  height: 20px;
  max-width: 20px;
  width: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
  flex-shrink: 0;

  &:hover {
    background: #eee !important;
  }
}