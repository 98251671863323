@use './loader.params' as p;

.root {
  #{p.$loader-color-property}: #f9f9f9;
  #{p.$loader-size-property}: 80px;
  position: relative;

  display: inline-block;
  width: p.$loader-size-value;
  height: p.$loader-size-value;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.side {
  position: absolute;

  display: block;
  box-sizing: border-box;
  width: calc(#{p.$loader-size-value} / 10 * 8);
  height: calc(#{p.$loader-size-value} / 10 * 8);
  margin: calc(#{p.$loader-size-value} / 10);

  border: calc(#{p.$loader-size-value} / 10) solid p.$loader-color-value;
  border-color: p.$loader-color-value transparent transparent transparent;
  border-radius: 50%;

  animation: app-loader-anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes app-loader-anim {
  to {
    transform: rotate(360deg);
  }
}
