.root {
  display: block;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}