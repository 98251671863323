@use '../../styles/colors';

.root {
  cursor: pointer;
  display: block;
  background: colors.$primary;
  color: colors.$reversed;
  font-size: 16px;
  padding: 15px;
  border-radius: 50px;
  user-select: none;
  text-align: center;
  transition: opacity 0.3s;
  text-decoration: none;
  border: none;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 12px;
    padding: 0.75em;
  }
}

.disabled {
  cursor: initial;
  pointer-events: none;
  filter: grayscale(1);
}

.xs {
  font-size: 12px;
  padding: 1em;
}

.md {

}
