.root {
  display: block;
  width: 100%;
  max-width: 360px;
  color: #fff;
  background: rgba(255, 255, 255, 0.05);;
  border-radius: 16px;
  border: none;
  padding: 10px 5px;
  margin: 0;
}

.item {
  display: flex;
  padding: 10px;
  border-radius: 16px;

  transition: 0.3s;
  cursor: pointer;

  &.itemActive {
    cursor: default;

    @media (max-width: 1200px), (max-height: 600px) {
      padding-bottom: 0;
    }
  }

  &:hover:not(.itemActive) {
    background: rgba(255,255,255,0.03);
  }
}

.content {
  width: 100%;
  padding: 0 15px;
  @media (max-width: 1200px), (max-height: 600px) {
    padding: 0 8px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  &.headerActive {
    margin-bottom: 20px;
  }

  @media (max-width: 1200px), (max-height: 600px) {
    padding-top: 5px;
  }
}

.primaryInfo {
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  padding: 3px 0;

  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 12px;
    padding: 0;
  }
}

.secondaryInfo {
  color: #aaa;
  line-height: 1;

  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 12px;
  }
}

.body {
  opacity: 0;
  margin-top: 12px;

  @media (max-height: 600px) {
    margin-top: 7px;
  }

  & :global {
    animation: fadeIn 0.7s forwards;
  }

}

.additionalInfo {
  text-align: center;
  font-size: 14px;
  color: #aaa;
  margin-bottom: 0;

  @media (max-width: 1200px), (max-height: 600px) {
    font-size: 12px;
    margin: 7px;
    margin-bottom: 0;
  }
}