/// SF PRO https://webfonts.pro/base-web-fonts/sans-serif-grotesque/955-san-francisco-pro.html
@font-face {
  font-weight: 500;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Medium.woff2") format("woff2");
}

@font-face {
  font-weight: 900;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Heavy.woff2") format("woff2");
}

@font-face {
  font-weight: 200;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-UltralightItalic.woff2") format("woff2");
}

@font-face {
  font-weight: normal;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-RegularItalic.woff2") format("woff2");
}

@font-face {
  font-weight: 100;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Thin.woff2") format("woff2");
}

@font-face {
  font-weight: 200;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Light.woff2") format("woff2");
}

@font-face {
  font-weight: 900;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-BlackItalic.woff2") format("woff2");
}

@font-face {
  font-weight: bold;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Bold.woff2") format("woff2");
}

@font-face {
  font-weight: 900;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Black.woff2") format("woff2");
}

@font-face {
  font-weight: 600;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-SemiboldItalic.woff2") format("woff2");
}

@font-face {
  font-weight: 200;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Ultralight.woff2") format("woff2");
}

@font-face {
  font-weight: 200;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-LightItalic.woff2") format("woff2");
}

@font-face {
  font-weight: 100;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-ThinItalic.woff2") format("woff2");
}

@font-face {
  font-weight: 500;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-weight: 600;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Semibold.woff2") format("woff2");
}

@font-face {
  font-weight: 900;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-HeavyItalic.woff2") format("woff2");
}

@font-face {
  font-weight: normal;
  font-family: "SF Pro Display";
  font-style: normal;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-Regular.woff2") format("woff2");
}

@font-face {
  font-weight: bold;
  font-family: "SF Pro Display";
  font-style: italic;
  font-display: swap;
  src: url("../../public/fonts/SFProDisplay-BoldItalic.woff2") format("woff2");
}

