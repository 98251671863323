.root {
  display: grid;
  position: relative;
  grid-template:
    'aside main'
    'aside main';
  height: 100vh;
  width: 100%;
  grid-template-columns: 380px 1fr;
  grid-template-rows: auto;

  @media (max-width: 1200px) {
    grid-template-columns: 320px 1fr;
  }

  @media (max-width: 750px) {
    grid-template:
    'aside'
    'main';
    grid-template-columns: auto;
    max-width: 500px;
    margin: 0 auto;
  }
}

.aside {
  padding: 20px;
  @media (max-width: 750px) {
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }
}

.main {
  padding: 10px;
  padding-left: 0;
  @media (max-width: 750px) {
    padding: 10px;
    padding-top: 0;
  }
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100%;
  background: rgba(0,0,0,0.8);
  backdrop-filter: blur(3px);
}

.popup {
  background: #131419;
  padding: 40px;
  border-radius: 20px;
  margin: 0 10px;
  border: 1px solid #303137;

  @media (max-width: 500px) {
    font-size: 20px;

    padding: 20px;
  }
}

.popupTitle {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.2em;

  @media (max-width: 500px) {
    font-size: 20px;
  }
}

.popupText {
  color: #999;
  margin: 0 auto;
  margin-bottom: 1.5em;
  text-align: center;

  @media (max-width: 500px) {
    font-size: 14px;
  }
}

.control {
  max-width: 150px;
  margin: 0 auto;
}

.controlDownload{
  margin-top: 30px;
}